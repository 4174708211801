/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function () {
				jQuery.browser = {};
				(function () {
					jQuery.browser.msie = false;
					jQuery.browser.version = 0;
					if (navigator.userAgent.match(/MSIE ([0-9]+)\./)) {
						jQuery.browser.msie = true;
						jQuery.browser.version = RegExp.$1;
					}
				})();
			},
			finalize: function () {
				// JavaScript to be fired on all pages, after page specific JS is fired
				if (!sessionStorage.getItem('popup_closed')) {
					$('.close').on('click', function (event) {
						event.preventDefault();
						console.log('clicked it');
						$('.popup').removeClass('slideInRight');
						$('.popup').addClass('slideOutRight');
						sessionStorage.setItem('popup_closed', true);
					});
				} else {
					$('body').addClass('popup-closed');
				}

				var captureOutboundLink = function(url) {
					ga('send', 'event', 'klassenzimmer', 'click', url, {
						'transport': 'beacon',
						'hitCallback': function(){document.location = url;}
					});
				};

				$('.klassenzimmer .card__button').click(function() {
					var addressValue = $(this).attr("href");
					captureOutboundLink(addressValue);
				});

				$('.card__tooltip').click(function () {
					$this = $(this);
					$this.children().html($this.children().html() == '?' ? 'X' : '?');
					$this.next().fadeToggle();
				});

				var sidebar = $('.sidebar__content');
				if (sidebar.children().length < 1) {
					$('body').addClass('sidebar-empty');
					$('body').removeClass('sidebar-primary');
				}

				function windowPopup(url, width, height) {
					// Calculate the position of the popup so
					// it’s centered on the screen.
					var left = (screen.width / 2) - (width / 2),
						top = (screen.height / 2) - (height / 2);

					window.open(
						url,
						"",
						"menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" + width + ",height=" + height + ",top=" + top + ",left=" + left
					);
				}

				$(".js-social-share").on("click", function (e) {
					e.preventDefault();

					windowPopup($(this).attr("href"), 500, 300);
				});


				$(document).on('scroll', function () {
					if ($(window).scrollTop() > 20) {
						$('#masthead').removeClass('is-attached');
						$('#masthead').addClass('is-scrolled');
					} else if ($(window).scrollTop() === 0) {
						$('#masthead').removeClass('is-scrolled');
						$('#masthead').addClass('is-attached');
					}
				});

				//open/close mega-navigation
				$('.cd-dropdown-trigger').on('click', function (event) {
					event.preventDefault();
					toggleNav();
				});

				function toggleNav() {
					var navIsVisible = (!$('.cd-dropdown').hasClass('dropdown-is-active')) ? true : false;
					$('.cd-dropdown').toggleClass('dropdown-is-active', navIsVisible);
					$('.cd-dropdown-trigger').toggleClass('dropdown-is-active', navIsVisible);
					if (!navIsVisible) {
						$('.cd-dropdown').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function () {
							$('.has-children ul').addClass('is-hidden');
							$('.move-out').removeClass('move-out');
							$('.is-active').removeClass('is-active');
						});
					}
				}

				//IE9 placeholder fallback
				//credits http://www.hagenburger.net/BLOG/HTML5-Input-Placeholder-Fix-With-jQuery.html
				if (!Modernizr.input.placeholder) {
					$('[placeholder]').focus(function () {
						var input = $(this);
						if (input.val() === input.attr('placeholder')) {
							input.val('');
						}
					}).blur(function () {
						var input = $(this);
						if (input.val() === '' || input.val() === input.attr('placeholder')) {
							input.val(input.attr('placeholder'));
						}
					}).blur();
					$('[placeholder]').parents('form').submit(function () {
						$(this).find('[placeholder]').each(function () {
							var input = $(this);
							if (input.val() === input.attr('placeholder')) {
								input.val('');
							}
						});
					});
				}


				//move nav element position according to window width
				function moveNavigation() {
					var navigation = $('.primary_nav');
					var screenSize = checkWindowWidth();
					if (screenSize) {
						//desktop screen - insert navigation inside header element
						navigation.detach();
						navigation.insertBefore('.cd-nav-trigger');
					} else {
						//mobile screen - insert navigation after .wrap element
						navigation.detach();
						navigation.insertAfter('.wrap');
					}
				}

				moveNavigation();

				$(window).on('resize', function () {
					if (!window.requestAnimationFrame) {
						setTimeout(moveNavigation, 300);
					} else {
						window.requestAnimationFrame(moveNavigation);
					}
				});

				//mobile version - open/close navigation
				$('.cd-nav-trigger').on('click', function (event) {
					event.preventDefault();
					if ($('header').hasClass('nav-is-visible')) {
						$('.moves-out').removeClass('moves-out');
					}

					$('header').toggleClass('nav-is-visible');
					$('.nav').toggleClass('nav-is-visible');
					$('.wrap').toggleClass('nav-is-visible');
				});

				//mobile version - go back to main navigation
				$('.go-back').on('click', function (event) {
					event.preventDefault();
					$('.nav').removeClass('moves-out');
				});

				//open sub-navigation
				$('.cd-subnav-trigger').on('click', function (event) {
					event.preventDefault();
					$('.nav').toggleClass('moves-out');
					$('.secondary_nav').toggleClass('moves-out');
				});

				function checkWindowWidth() {
					if (!document.body.classList.contains('page-template-template-elearning')) {
						var mq = window.getComputedStyle(document.querySelector('header'), '::before').getPropertyValue('content').replace(/"/g, '').replace(/'/g, "");
						return (mq === 'mobile') ? false : true;
					}
				}

				var MqL = 800;
				//open search form
				$('.search_form_trigger').on('click', function (event) {
					event.preventDefault();
					toggleSearch();
				});

				function toggleSearch(type) {
					if (type === "close") {
						//close search
						$('.search_form').removeClass('is-visible');
						$('.search_form_trigger').removeClass('search-is-visible');
						$('.overlay').removeClass('search-is-visible');
					} else {
						//toggle search visibility
						$('.search_form').toggleClass('is-visible');
						$('.search_form_trigger').toggleClass('search-is-visible');
						$('.overlay').toggleClass('search-is-visible');

						if ($(window).width() > MqL && $('.search_form').hasClass('is-visible')) {
							$('.search_form').find('input[type="search"]').focus();
						}

						if ($('.search_form').hasClass('is-visible')) {
							$('.overlay').addClass('is-visible');
						} else {
							$('.overlay').removeClass('is-visible');
						}
					}
				}

				var $timeline_block = $('.timeline_module__event');

				//hide timeline blocks which are outside the viewport
				$timeline_block.each(function () {
					if ($(this).offset().top > $(window).scrollTop() + $(window).height() * 0.75) {
						$(this).find('.timeline_module__year, .timeline_module__content').addClass('is-hidden');
					}
				});

				//on scolling, show/animate timeline blocks when enter the viewport
				$(window).on('scroll', function () {
					$timeline_block.each(function () {
						if ($(this).offset().top <= $(window).scrollTop() + $(window).height() * 0.75 && $(this).find('.timeline_module__year').hasClass('is-hidden')) {
							$(this).find('.timeline_module__year, .timeline_module__content').removeClass('is-hidden').addClass('bounce-in');
						}
					});
				});

				var question;
				var rightAnswer;
				var resultsContainer = document.getElementById('results');
				var submitButton = document.getElementById('submit');
				var raffleButton = document.getElementById('raffle');
				var quizContainer = document.getElementById('quiz');
				var questionContainer = document.querySelector('#quiz .card__headline');
				var answer1Container = document.querySelector('[data-index="1"]');
				var answer2Container = document.querySelector('[data-index="2"]');
				var answer3Container = document.querySelector('[data-index="3"]');
				var answer1;
				var answer2;
				var answer3;
				var rightAnswerContainer = document.querySelector('[data-correct="correct"]');

				if (questionContainer) {
					question = questionContainer.getAttribute('data-question');
				}

				if (rightAnswerContainer) {
					rightAnswer = rightAnswerContainer.getAttribute('data-index');
				}

				if (answer1Container) {
					answer1 = answer1Container.getAttribute('data-answer');
				}

				if (answer2Container) {
					answer2 = answer2Container.getAttribute('data-answer');
				}

				if (answer2Container) {
					answer2 = answer2Container.getAttribute('data-answer');
				}

				var myQuestions = [
					{
						question: question,
						answers: {
							1: answer1,
							2: answer2,
							3: answer3
						},
						correctAnswer: rightAnswer,
					},
				];

				// quiz
				function showResults(questions, quizContainer, resultsContainer) {

					// gather answer containers from our quiz
					var answerContainers = quizContainer.querySelectorAll('.answers');
					// keep track of user's answers
					var userAnswer = '';
					var numCorrect = 0;

					// for each question...
					for (var i = 0; i < questions.length; i++) {

						// find selected answer
						userAnswer = (answerContainers[i].querySelector('input[name=question]:checked') || {}).value;

						// if answer is correct
						if (userAnswer === questions[i].correctAnswer) {
							// add to the number of correct answers
							numCorrect++;
							resultsContainer.querySelector('.result__correct').classList.remove('is-hidden');
						} else {
							resultsContainer.querySelector('.result__wrong').classList.remove('is-hidden');
						}
					}
				}

				if (sessionStorage.getItem("quizStatus") != "has-played") {
					$('.has-played').hide();
				}

				if (submitButton !== null) {
					submitButton.onclick = function () {
						if (sessionStorage.getItem("quizStatus") != "has-played") {
							showResults(myQuestions, quizContainer, resultsContainer);
							$('#results').fadeIn();
						}

						sessionStorage.setItem("quizStatus", "has-played");
					};
				}

				if (sessionStorage.getItem("quizStatus") == "has-played") {
					$('.answers').hide();
					$('#quiz .card__headline').hide();
					$('#quiz #submit').hide();
					$('.has-played').show();
				}

				if (raffleButton !== null) {
					raffleButton.onclick = function () {
						$('.raffle').fadeIn();
					};
				}

				// accordion for list module
				(function () {
					var d = document,
						accordionToggles = d.querySelectorAll('.js-list_module__trigger'),
						setAria,
						setAccordionAria,
						switchAccordion,
						touchSupported = ('ontouchstart' in window),
						pointerSupported = ('pointerdown' in window);

					skipClickDelay = function (e) {
						e.preventDefault();
						e.target.click();
					};

					setAriaAttr = function (el, ariaType, newProperty) {
						el.setAttribute(ariaType, newProperty);
					};
					setAccordionAria = function (el1, el2, expanded) {
						switch (expanded) {
							case "true":
								setAriaAttr(el1, 'aria-expanded', 'true');
								setAriaAttr(el2, 'aria-hidden', 'false');
								break;
							case "false":
								setAriaAttr(el1, 'aria-expanded', 'false');
								setAriaAttr(el2, 'aria-hidden', 'true');
								break;
							default:
								break;
						}
					};

					//function
					switchAccordion = function (e) {
						e.preventDefault();
						var thisAnswer = e.target.nextElementSibling;
						var thisQuestion = e.target;
						if (thisAnswer.classList.contains('is-collapsed')) {
							setAccordionAria(thisQuestion, thisAnswer, 'true');
						} else {
							setAccordionAria(thisQuestion, thisAnswer, 'false');
						}

						thisQuestion.classList.toggle('is-collapsed');
						thisQuestion.classList.toggle('is-expanded');
						thisAnswer.classList.toggle('is-collapsed');
						thisAnswer.classList.toggle('is-expanded');

						thisAnswer.classList.toggle('animateIn');
					};

					for (var i = 0, len = accordionToggles.length; i < len; i++) {
						if (touchSupported) {
							accordionToggles[i].addEventListener('touchstart', skipClickDelay, false);
						}
						if (pointerSupported) {
							accordionToggles[i].addEventListener('pointerdown', skipClickDelay, false);
						}
						accordionToggles[i].addEventListener('click', switchAccordion, false);
					}
				})();

				// Responsible Font loading with Font-Events by Filament Group
				(function () {
					/*! cookie function. get, set, or forget a cookie. [c]2014 @scottjehl, Filament Group, Inc. Licensed MIT */
					function cookie(name, value, days) {
						// if value is undefined, get the cookie value
						if (value === undefined) {
							var cookiestring = "; " + window.document.cookie;
							var cookies = cookiestring.split("; " + name + "=");
							if (cookies.length === 2) {
								return cookies.pop().split(";").shift();
							}
							return null;
						}
						else {
							// if value is a false boolean, we'll treat that as a delete
							if (value === false) {
								days = -1;
							}
							var expires;
							if (days) {
								var date = new Date();
								date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
								expires = "; expires=" + date.toGMTString();
							}
							else {
								expires = "";
							}
							window.document.cookie = name + "=" + value + expires + "; path=/";
						}
					}

					// if the class is already set, we're good.
					if (document.documentElement.className.indexOf("fonts-loaded") > -1) {
						return;
					}
					var fontA = new FontFaceObserver("Source Sans Pro", {
						weight: 400
					});
					var fontB = new FontFaceObserver("Source Sans Pro", {
						weight: 600
					});
					var fontC = new FontFaceObserver("Source Sans Pro", {
						weight: 400,
						style: "italic"
					});
					Promise
						.all([fontA.check(), fontB.check(), fontC.check()])
						.then(function () {
							document.documentElement.className += " fonts-loaded";
						});
					cookie("fonts", "fonts-loaded=true", 7);
				}(this));
			},
		},
		// Home page
		'home': {
			init: function () {
				// JavaScript to be fired on the home page
			},
			finalize: function () {
				// JavaScript to be fired on the home page, after the init JS
			},
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function () {
				// JavaScript to be fired on the about us page
			},
		},
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

// Google Maps API w ACF and async
// render_map
function initialize() {
	function render_map($el) {
		// var
		var $markers = $el.find('.contact_module__marker');

		var styles = [
			{
				"featureType": "administrative.province",
				"elementType": "all",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "landscape",
				"elementType": "all",
				"stylers": [
					{
						"saturation": -100
					},
					{
						"lightness": 65
					},
					{
						"visibility": "on"
					}
				]
			},
			{
				"featureType": "poi",
				"elementType": "all",
				"stylers": [
					{
						"saturation": -100
					},
					{
						"lightness": 51
					},
					{
						"visibility": "simplified"
					}
				]
			},
			{
				"featureType": "road.highway",
				"elementType": "all",
				"stylers": [
					{
						"saturation": -100
					},
					{
						"visibility": "simplified"
					}
				]
			},
			{
				"featureType": "road.highway",
				"elementType": "geometry.fill",
				"stylers": [
					{
						"color": jQuery('.contact_module__map').attr('data-color')
					}
				]
			},
			{
				"featureType": "road.arterial",
				"elementType": "all",
				"stylers": [
					{
						"saturation": -100
					},
					{
						"lightness": 30
					},
					{
						"visibility": "on"
					}
				]
			},
			{
				"featureType": "road.local",
				"elementType": "all",
				"stylers": [
					{
						"saturation": -100
					},
					{
						"lightness": 40
					},
					{
						"visibility": "on"
					}
				]
			},
			{
				"featureType": "transit",
				"elementType": "all",
				"stylers": [
					{
						"saturation": -100
					},
					{
						"visibility": "simplified"
					}
				]
			},
			{
				"featureType": "water",
				"elementType": "geometry",
				"stylers": [
					{
						"hue": jQuery('.contact_module__map').attr('data-color')
					},
					{
						"lightness": -25
					},
					{
						"saturation": -97
					}
				]
			},
			{
				"featureType": "water",
				"elementType": "geometry.fill",
				"stylers": [
					{
						"color": jQuery('.contact_module__map').attr('data-color')
					}
				]
			},
			{
				"featureType": "water",
				"elementType": "labels",
				"stylers": [
					{
						"visibility": "on"
					},
					{
						"lightness": -25
					},
					{
						"saturation": -100
					}
				]
			}
		];

		// vars
		var args = {
			zoom: 16,
			center: new google.maps.LatLng(0, 0),
			scrollwheel: false,
			mapTypeControlOptions: {
				mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'gskmap']
			}
		};

		// create map
		var map = new google.maps.Map($el[0], args);

		// styling
		var styledMapOptions = {
			name: 'Greyscale'
		};

		var gskMapType = new google.maps.StyledMapType(styles, styledMapOptions);

		map.mapTypes.set('gskmap', gskMapType);
		map.setMapTypeId('gskmap');

		// add a markers reference
		map.markers = [];

		// add markers
		$markers.each(function () {
			add_marker(jQuery(this), map);
		});

		// center map
		center_map(map);
	}

	// add_marker
	function add_marker($marker, map) {
		// var
		var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

		// create marker
		var marker = new google.maps.Marker({
			position: latlng,
			map: map
		});

		// add to array
		map.markers.push(marker);

		// if marker contains HTML, add it to an infoWindow
		if ($marker.html()) {
			// create info window
			var infowindow = new google.maps.InfoWindow({
				content: $marker.html()
			});

			// show info window when marker is clicked
			google.maps.event.addListener(marker, 'click', function () {
				infowindow.open(map, marker);
			});
		}

	}

	// center_map
	function center_map(map) {

		// vars
		var bounds = new google.maps.LatLngBounds();

		// loop through all markers and create bounds
		jQuery.each(map.markers, function (i, marker) {
			var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
			bounds.extend(latlng);
		});

		// only 1 marker?
		if (map.markers.length === 1) {
			// set center of map
			map.setCenter(bounds.getCenter());
			map.setZoom(15);
		} else {
			// fit to bounds
			map.fitBounds(bounds);
		}
	}

	jQuery('.contact_module__map').each(function () {
		render_map(jQuery(this));
	});
}

function loadScript() {
	var script = document.createElement('script');
	script.type = 'text/javascript';
	script.src = 'https://maps.googleapis.com/maps/api/js?v=3.exp' +
		'&key=AIzaSyClt6IPuLqxMdwIaIKDARYKTPdOcRFnMqo&callback=initialize';
	document.body.appendChild(script);
}

window.onload = loadScript;
